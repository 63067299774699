
import Vue, { PropType } from "vue";
import { UserLevel } from "@/modules/product/enum/UserLevel";

export interface MenuItem {
  title: string;
  icon?: string;
  svg?: string;
  link?: string;
  exact?: boolean;
  target?: string;
  route?: {
    name: string;
  };
  level: UserLevel[];
  children?: MenuItem[];
}

export default Vue.extend({
  name: "KMenu",
  props: {
    menuItems: {
      type: Array as PropType<MenuItem[]>,
      default: () => [] as MenuItem[],
    },
  },
});
