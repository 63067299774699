
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import ClientSelector from "@/modules/client/components/ClientSelector.vue";

export default Vue.extend({
  name: "TheAuthorisedAppBar",
  components: { ClientSelector },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      profile: "authorisation/profile",
    }),
  },
  methods: {
    ...mapActions({
      logout: "authorisation/logout",
    }),
    async handleLogout() {
      await this.logout();
      window.location.reload();
    },
  },
});
